import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  TextField as MUITextField,
  Paper,
  Stack,
} from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  Checkbox,
  DadosBenchmarking,
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  FileField,
  FileListField,
  Select,
  TextField,
  Timestamps,
} from "../../components";
import api from "../../services/api";
import DadosDiagnostico from "./DadosDiagnostico";
import DialogoAcompanhaOrcamentos from "./DialogoAcompanhaOrcamentos";
import DialogoSolicitacaoOrcamentos from "./DialogoSolicitacaoOrcamentos";
import DialogoSolicitacaoOrcamentosNovos from "./DialogoSolicitacaoOrcamentosNovos";
import DialogoAcompanhaOrcamentosTabNovo from "./DialogoAcompanhaOrcamentosTabNovo";
yup.setLocale(pt);

const Diagnostico = () => {
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [listaAuditores, setListaAuditores] = React.useState([]);
  const [listaScrumMasters, setListaScrumMasters] = React.useState([]);
  const [diagnostico, setDiagnostico] = React.useState(null);
  const [diagnosticoId, setDiagnosticoId] = React.useState(null);
  const [listaInstFinanceiras, setListaInstFinanceiras] = React.useState([]);
  const [deveReabrirDiagnostico, setDeveReabrirDiagnostico] = React.useState(false);
  const [showObsErrorMsg, setShowObsErrorMsg] = React.useState(false);

  const [exibirScrumMaster, setExibirScrumMaster] = React.useState(false);
  const [exibirAuditor, setExibirAuditor] = React.useState(false);

  const schema = yup
    .object({
      auditorId: yup
        .number()
        .positive()
        .label("Escolha um especialista")
        .required(),
      scrumMasterId: yup
        .number()
        .positive()
        .label("Escolha um Especialista Lider")
        .nullable(),
      pendenciasId: yup.number().positive().nullable().label("Pendências"),
      pendenciasSanadas: yup.boolean().label("Pendências sanadas"),
      relatorioId: yup.number().positive().nullable().label("Relatório"),
      aprovadoCliente: yup.boolean().label("Aprovado pelo cliente"),
      outrosDocsField: yup
        .array()
        .label("Arraste outros documentos aqui ou clique"),
      comentariosEsp: yup
        .string()
        .nullable()
        .label("Comentários do especialista"),
      comentariosRev: yup.string().nullable().label("Comentários do revisor"),
      aprovadoValidador: yup.boolean().label("Projeto validado"),
      aprovadoGci: yup
        .boolean()
        .label("Aprovação do enquadramento do projeto (GCI)"),
      instFinanceiraId: yup
        .number()
        .positive()
        .nullable()
        .label("Instituição Financeira"),
      motivoEncerramento: yup
        .number()
        .positive()
        .nullable()
        .label("Favor elencar os motivos de não implementação do projeto"),
      observacoesEncerramento: yup.string().nullable().label("Observações"),
      termoApresDiagId: yup
        .number()
        .positive()
        .nullable()
        .label("Termo de Apresentação do Diagnóstico"),
      modalidadeFinanciamento: yup
        .string()
        .nullable()
        .label("Selecione a Modalidade de Implementação do Projeto"),
    })
    .required();

  const defaultValues = {
    auditorId: "",
    scrumMasterId: "",
    pendenciasId: null,
    relatorioId: null,
    outrosDocsField: [],
    instFinanceiraId: null,
    motivoEncerramento: null,
    observacoesEncerramento: "",
    termoApresDiagId: null,
    modalidadeFinanciamento: "",
  };

  const listaModalidadesFinanciamentos = [
    {
      nome: "Financiamento via Instituições Financeiras",
      id: "F",
    },
    {
      nome: "Recursos Próprios",
      id: "R",
    },
    {
      nome: "BOT",
      id: "B",
    },
    {
      nome: "Não Realizará a Implementação",
      id: "N",
    },
  ];

  const listaMotivosEncerramento = [
    {
      id: 1,
      label: "Saúde Financeira da Empresa",
    },
    {
      id: 2,
      label:
        "Pendência Documental da Empresa (licenciamento ambiental, contrato sindical, CND, etc)",
    },
    {
      id: 3,
      label: "Preferência em Solicitar o Financiamento em Momento Futuro",
    },
    {
      id: 4,
      label: "Baixa Atratividade Financeira do Projeto",
    },
    {
      id: 5,
      label: "Inviabilidade Técnica do Projeto",
    },
    {
      id: 6,
      label: "Dificuldade com Fornecedores",
    },
    {
      id: 7,
      label: "Outra Razão (Especificar nas Observações)",
    },
  ];

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const auditorId = watch("auditorId");
  const scrumMasterId = watch("scrumMasterId");
  const termoApresDiagId = watch("termoApresDiagId");
  const modalidadeFinanciamento = watch("modalidadeFinanciamento");
  const instFinanceiraId = watch("instFinanceiraId");
  const motivoWatch = watch("motivoEncerramento");
  const obsWatch = watch("observacoesEncerramento");
  React.useEffect(() => {
    setShowObsErrorMsg(false);
  }, [obsWatch]);

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  React.useEffect(() => {
    let loadInstituicoesFinanceiras = async () => {
      startLoading();
      try {
        setListaInstFinanceiras(
          (
            await api.http.get("/inst-financeiras", {
              params: { habilitado: true },
            })
          ).data
        );
        stopLoading();
      } catch (error) {
        stopLoading({
          message: error.toString(),
          severity: "error",
        });
      }
    };

    loadInstituicoesFinanceiras();
  }, []);

  const verificaPreenchimento = () => {
    let erros = [];
    if (getValues("pendenciasId") && !getValues("pendenciasSanadas"))
      erros.push("Sanar pendências informadas");
    if (!getValues("relatorioId")) erros.push("Inclusão de relatório");
    else if (!getValues("aprovadoCliente"))
      erros.push("Aprovação do relatório pelo cliente");
    if (
      !getValues("field_2_6") ||
      !getValues("field_3_4") ||
      !getValues("field_3_5") ||
      !getValues("field_3_6") ||
      !getValues("field_3_7") ||
      !getValues("field_3_8") ||
      !getValues("field_4_11") ||
      !getValues("field_4_12") ||
      !getValues("field_4_13")
    )
      erros.push(
        "Inclusão obrigatória dos itens 2.6, 3.4, 3.5, 3.6, 3.7, 3.8, " +
        "4.11, 4.12 e 4.13 dos dados do diagnóstico"
      );
    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const validateEncerramentoInfo = (data) => {
    return (
      data.motivoEncerramento === 7 &&
      (data.observacoesEncerramento == null ||
        data.observacoesEncerramento === "" ||
        data.observacoesEncerramento?.trim()?.length === 0)
    );
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja <b>aprovar</b>{" "}
          o diagnóstico energético para o financiamento?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        Esta ação não pode ser desfeita. Tem certeza que deseja <b>reprovar</b>o
        diagnóstico energético?
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerRecusa = () => {
    setOpenConfirmaDesfRecusa(true);
  };

  const salva = async (data) => {
    startLoading();
    try {

      let diagnosticoData;
      if (data.id) {
        if (
          data.modalidadeFinanciamento === "N" &&
          data.estagio === undefined
        ) {
          data.estagio = "encSemImplementacao";
        }
        if (data.modalidadeFinanciamento === "R") {
          data.estagio = "finRecProprio";
        }
        if (data.modalidadeFinanciamento === "B") {
          data.estagio = "financiadoBOT";
        }
        if (
          getValues("motivoEncerramento") !== null &&
          !deveReabrirDiagnostico &&
          !isDirty
        ) {
          if (validateEncerramentoInfo(data)) {
            setShowObsErrorMsg(true);
            stopLoading({ severity: "error" });
            return;
          } else {
            setShowObsErrorMsg(false);
          }
          data.estagio = "encSemImplementacao";
        } else if (deveReabrirDiagnostico) {
          data.estagio = "diagIniciado";
        }
        if (termoApresDiagId === null) {
          data.estagio = "diagIniciado";
        } else if (
          currProcesso.estagio === "diagIniciado" &&
          termoApresDiagId !== null
        ) {
          data.estagio = "apreCliente";
        }
        if (
          currProcesso.estagio === "apreCliente" &&
          data.modalidadeFinanciamento === "F" &&
          aprovadoValidador
        ) {
          data.estagio = "diagAprovadoIFPA";
        }
        if (currProcesso.estagio === "diagAprovadoIF" && aprovadoGci) {
          data.estagio = "diagAprovadoIF";
        }
        diagnosticoData = (await api.http.put("/diagnosticos/" + data.id, data))
          .data;

      } else {
        let post = (
          await api.http.post("/diagnosticos", {
            ...data,
            processoId: currProcesso.id,
          })
        ).data;
        diagnosticoData = post.diagnostico;
        selectProcesso(post.processo);
      }
      let diagnosticoRetornado = null;
      if (
        data.estagio === "apreCliente" ||
        data.estagio === "diagAprovadoIF" ||
        data.estagio === "diagAprovadoIFPA" ||
        data.estagio === "finRecProprio" ||
        data.estagio === "financiadoBOT" ||
        data.estagio === "encSemImplementacao" ||
        deveReabrirDiagnostico
      ) {
        diagnosticoRetornado = diagnosticoData.diagnostico;
        selectProcesso(diagnosticoData.processo);
        setDeveReabrirDiagnostico(false);
      } else {
        diagnosticoRetornado = diagnosticoData.diagnostico;
        if (!diagnosticoRetornado) {
          diagnosticoRetornado = diagnosticoData;
        }
      }
      /* Retornando codigo comentado pelo José Eustáquio pedido pelo Lucas em 29/02/2024 */
      if (diagnostico?.id && auditorId && exibirAuditor) {
        let dadosBenchmarking = (
          await api.http.get("/benchmarkings", {
            params: {
              processoId: currProcesso.id,
              final: false,
            },
          })
        ).data.data;
        if (dadosBenchmarking == null || dadosBenchmarking.length === 0) {
          setShowObsErrorMsg(true);
          stopLoading({
            message: "Dados de Benchmarking são obrigatórios",
            severity: "error",
          });
          return;
        }
      }
      /* ./end -> Retornando codigo comentado pelo José Eustáquio pedido pelo Lucas em 29/02/2024 */

      if (diagnostico?.id === undefined || diagnostico?.id === null || (data.estagio === undefined && data.termoApresDiagId !== null)) {
        let diagnosticoData2 = (
          await api.http.get("/diagnosticos/" + currProcesso.id)
        ).data;

        setDiagnosticoId(diagnosticoData2.id);

        reset(diagnosticoData2);

        setDiagnostico(diagnosticoData2);
      }
      // let dadosBenchmarking = (await api.http.get('/benchmarkings',{
      //   params: {
      //     processoId: currProcesso.id,
      //     final: false
      //   }
      // })).data.data
      // if (dadosBenchmarking == null || dadosBenchmarking.length === 0) {
      //   setShowObsErrorMsg(true);
      //   stopLoading({
      //     message: "Dados de Benchmarking são obrigatórios",
      //     severity: "error"
      //   });
      //   return;
      // }
      /* ./end -> Retornando codigo comentado pelo José Eustáquio pedido pelo Lucas em 29/02/2024 */
      stopLoading({ severity: "success" });
      reset(diagnosticoRetornado);
      setDiagnostico(diagnosticoRetornado);

      if (diagnosticoRetornado?.auditorId) {
        setExibirAuditor(true);
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      //  estagio: aprovando ? "diagAprovado" : "diagReprovado",
    };
    startLoading();
    try {
      let post = (
        await api.http.put("/diagnosticos/" + diagnostico.id, confirmaData)
      ).data;
      setDiagnostico(post.diagnostico);
      reset(post.diagnostico);
      selectProcesso(post.processo);
      stopLoading({ severity: "success" });
      window.location.reload();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfRecusa, setOpenConfirmaDesfRecusa] =
    React.useState(false);

  const onConfirmaDesfRecusa = async (data) => {
    setOpenConfirmaDesfRecusa(false);
    const confirmaData = {
      estagio: "diagIniciado",
    };
    startLoading();
    try {
      let put = (
        await api.http.put("/diagnosticos/" + diagnostico.id, confirmaData)
      ).data;

      setDiagnostico(put.diagnostico);
      reset(put.diagnostico);
      selectProcesso(put.processo);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onNaoConfirmaDesfRecusa = () => {
    setOpenConfirmaDesfRecusa(false);
  };

  let user = useStoreState((state) => state.auth.user);

  const disabledAlocacao = () => {
    return (
      (user.roleId !== "superAdmin" &&
        !["diagIniciado", "apreCliente", "preDiagAprovado"].includes(
          currProcesso.estagio
        )) ||
      !["consultorEE", "superAdmin", "gestorSENAI", "scrumMaster"].includes(user.roleId) ||
      "encSemImplementacao" === currProcesso.estagio
    );
  };

  const disabled = () => {
    return (
      (user.roleId !== "superAdmin" &&
        !["diagIniciado", "apreCliente", "preDiagAprovado"].includes(
          currProcesso.estagio
        )) ||
      // (user.roleId == "consultorEE" && auditorId != user.id) ||
      !["consultorEE", "superAdmin", "gestorSENAI", "scrumMaster"].includes(user.roleId) ||
      "encSemImplementacao" === currProcesso.estagio
    );
  };

  const disabledValidador = () => {
    return (
      !["diagIniciado", "apreCliente", "preDiagAprovado"].includes(
        currProcesso.estagio
      ) ||
      ![
        "consultorEE",
        "superAdmin",
        "validadorIndSENAI",
        "validadorIndGIZ",
      ].includes(user.roleId)
    );
  };

  const disabledGci = () => {
    return (
      ![
        "diagIniciado",
        "diagAprovadoIFPA",
        "apreCliente",
        "preDiagAprovado",
      ].includes(currProcesso.estagio) ||
      !["gestorGarantidor", "gestorGIZ"].includes(user.roleId)
    );
  };

  const [openDadosDiagnostico, setOpenDadosDiagnostico] = React.useState(false);

  const dadosDiagnostico = () => {
    setOpenDadosDiagnostico(true);
  };

  const onSaveDadosDiagnostico = async (data) => {
    startLoading();
    try {
      setDiagnostico(
        (await api.http.put("/diagnosticos/" + diagnostico.id, data)).data
      );
      stopLoading({ severity: "success" });
      setOpenDadosDiagnostico(false);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onCancelDadosDiagnostico = () => {
    setOpenDadosDiagnostico(false);
  };

  const [openDadosBenchmarking, setOpenDadosBenchmarking] =
    React.useState(false);

  const dadosBenchmarking = () => {
    setOpenDadosBenchmarking(true);
  };

  const onCloseDadosBenchmarking = () => {
    setOpenDadosBenchmarking(false);
  };

  const [openDlgSolOrcs, setOpenDlgSolOrcs] = React.useState(false);

  const solicitaOrcamento = () => {
    setOpenDlgSolOrcs(true);
  };

  const onCloseDlgSolOrcs = () => {
    setOpenDlgSolOrcs(false);
  };

  const [openDlgAcomOrcs, setOpenDlgAcomOrcs] = React.useState(false);

  const acompanhaOrcamento = () => {
    setOpenDlgAcomOrcs(true);
  };

  const onCloseDlgAcomOrcs = () => {
    setOpenDlgAcomOrcs(false);
  };

  React.useEffect(() => {
    const loadDiagnosticoData = async () => {
      startLoading();
      try {
        setListaAuditores(
          (
            await api.http.get("/users", {
              params: {
                roleId: "consultorEE",
                habilitado: true,
                ordenado_alfa: true,
              },
            })
          ).data
        );

        setListaScrumMasters(
          (
            await api.http.get("/users", {
              params: {
                roleId: "scrumMaster",
                habilitado: true,
                ordenado_alfa: true,
              },
            })
          ).data
        );

        if (currProcesso.scrumNotApply === 2) {
          setExibirScrumMaster(true);
        }

        if (currProcesso.estagio !== "preDiagAprovado") {

          let diagnosticoData = (
            await api.http.get("/diagnosticos/" + currProcesso.id)
          ).data;
          reset(diagnosticoData);
          setDiagnostico(diagnosticoData);


          if (diagnosticoData.scrumMasterId) {
            setExibirScrumMaster(true);
          }
          if (diagnosticoData.auditorId) {
            setExibirAuditor(true);
          }

        }
        else {
          if (!scrumMasterId) {
            //método que recupera o Especialista Lider da fase anterior
            const visitaTecnicaData = (
              await api.http.get("/visitas_tecnicas/" + currProcesso.id)
            ).data;
            //atualizar o valor do Especialista Lider
            setValue("scrumMasterId", visitaTecnicaData.scrumMasterId);
          }
        }
        stopLoading();
      } catch (error) {
        stopLoading({
          message: error.toString(),
          severity: "error",
        });
      }
    };
    loadDiagnosticoData();
  }, []);

  const aprovadoValidador = watch("aprovadoValidador");
  const aprovadoGci = watch("aprovadoGci");

  useEffect(() => {
    if (deveReabrirDiagnostico === true) {
      handleSubmit(salva)();
      setDeveReabrirDiagnostico(false);
    }
  }, [deveReabrirDiagnostico]);

  const actionButtons2 = () => {
    return (
      <React.Fragment>
        {/* <Button
          variant="outlined"
          size="large"
          sx={{ mr: 2 }}
          onClick={cancela}
        // disabled={!isDirty || isSubmitting}
        >
          CANCELAR
        </Button> */}
        <Button
          variant="contained"
          size="large"
          onClick={handleSalvarScrumMaster}
        //  disabled={!isDirty || isSubmitting}
        >
          SALVAR
        </Button>
      </React.Fragment>
    );
  }

  const actionButtons = () => {
    if (
      isDirty ||
      !diagnostico ||
      (currProcesso.estagio !== "diagAprovadoIFPA" &&
        currProcesso.estagio !== "encSemImplementacao") ||
      (modalidadeFinanciamento !== "R" &&
        currProcesso.estagio !== "encSemImplementacao" &&
        !aprovadoGci) ||
      (modalidadeFinanciamento === "N" &&
        currProcesso.estagio !== "encSemImplementacao")
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={cancela}
            disabled={!isDirty || isSubmitting}
          >
            CANCELAR
          </Button>
          {modalidadeFinanciamento === "N" && !isDirty && (
            <Button
              variant="contained"
              color="error"
              size="large"
              sx={{ mr: 2 }}
              onClick={handleSubmit(salva)}
              disabled={isSubmitting}
            >
              ENCERRAR DIAGNÓSTICO
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit(salva)}
            disabled={!isDirty || isSubmitting}
          >
            SALVAR
          </Button>
        </React.Fragment>
      );
    } else if (currProcesso.estagio === "encSemImplementacao") {
      return (
        <Button
          variant="outlined"
          size="large"
          sx={{ mr: 2 }}
          onClick={() => {
            setDeveReabrirDiagnostico(true);
          }}
          disabled={isSubmitting}
        >
          REABRIR DIAGNÓSTICO
        </Button>
      );
    } else {
      if (
        user.roleId === "superAdmin" &&
        currProcesso.estagio === "diagReprovado"
      ) {
        return (
          <React.Fragment>
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={desfazerRecusa}
            >
              DESFAZER PROCESSO RECUSADO
            </Button>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {/* <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={recusa}
            >
              RECUSAR PARA FINANCIAMENTO
            </Button> */}
            <Button
              variant="contained"
              size="large"
              onClick={aprova}
              disabled={
                !aprovadoValidador ||
                !aprovadoGci ||
                !modalidadeFinanciamento ||
                (modalidadeFinanciamento === "F" && !instFinanceiraId)
              }
            >
              APROVAR PARA FINANCIAMENTO
            </Button>
          </React.Fragment>
        );
      }
    }
  };

  const handleSalvarScrumMaster = async () => {

    // Coloque a lógica para salvar o Especialista Lider aqui
    startLoading();
    try {
      let retorno;

      retorno = (
        await api.http.post("/diagnosticos/scrummaster", {
          processoId: currProcesso.id,
          scrumMasterId: scrumMasterId,
        })
      ).data;

      stopLoading({ severity: "success" });
      reset(retorno.diagnostico);
      setDiagnostico(retorno.diagnostico);
      selectProcesso(retorno.processo);
      setExibirScrumMaster(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  // console.log("exibir scrum master ", exibirScrumMaster);


  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      {(!exibirScrumMaster && currProcesso.scrumNotApply === 2) && (
        <>
          <form>
            <Divider>
              <h3>Alocação do Especialista Lider do projeto</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="scrumMasterId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaScrumMasters}
                getOptionLabel={(option) => option.nome}
              //disabled={disabledBtScrumMaster()}
              />
            </FormControl>

            <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons2()}</Box>

          </form>
        </>
      )}

      {(diagnostico?.id || diagnosticoId) && exibirScrumMaster && exibirAuditor && auditorId && (
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="outlined" onClick={dadosDiagnostico}>
            DADOS DE DIAGNÓSTICO
          </Button>
          <Button
            variant="outlined"
            onClick={dadosBenchmarking}
            endIcon={<ReportIcon />}
          >
            DADOS DE BENCHMARKING
          </Button>
          <Button variant="outlined" onClick={solicitaOrcamento}>
            SOLICITA ORÇAMENTO
          </Button>
          <Button variant="outlined" onClick={acompanhaOrcamento}>
            ACOMPANHA ORÇAMENTO
          </Button>
        </Stack>
      )}

      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
        criteriosUrl="https://venidera.sharepoint.com/:w:/s/PotencializEE/ETrFABhWUj9OqN5LcoyN6hcBLH-JrywqX7HxhZWTAqLXFQ?e=gWHtss"
        disabledSim={
          aprovando &&
          (!getValues("field_2_6") ||
            !getValues("field_3_4") ||
            !getValues("field_3_5") ||
            !getValues("field_3_6") ||
            !getValues("field_3_7") ||
            !getValues("field_3_8") ||
            !getValues("field_4_11") ||
            !getValues("field_4_12") ||
            !getValues("field_4_13"))
        }
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfRecusa}
        onSim={onConfirmaDesfRecusa}
        onNao={onNaoConfirmaDesfRecusa}
      >
        Tem certeza que deseja desfazer recusa do diagnóstico?
      </DialogoConfirmacaoSimples>

      {currProcesso.isOrcamentoNovo === 1 && (
        <>
          <DialogoSolicitacaoOrcamentos
            open={openDlgSolOrcs}
            onClose={onCloseDlgSolOrcs}
            disabled={disabled()}
          />
          <DialogoAcompanhaOrcamentos
            open={openDlgAcomOrcs}
            onClose={onCloseDlgAcomOrcs}
            disabled={disabled()}
          />
        </>
      )}

      {currProcesso.isOrcamentoNovo !== 1 && (
        <>
          <DialogoSolicitacaoOrcamentosNovos
            open={openDlgSolOrcs}
            onClose={onCloseDlgSolOrcs}
            disabled={disabled()}
          />
          <DialogoAcompanhaOrcamentosTabNovo
            open={openDlgAcomOrcs}
            onClose={onCloseDlgAcomOrcs}
            disabled={disabled()}
          />
        </>
      )}



      {exibirScrumMaster && (
        <>
          <form onSubmit={handleSubmit(aprova)}>
            {currProcesso.scrumNotApply === 2 && (
              <>
                <Divider>
                  <h3>Alocação do Especialista Lider do Projeto</h3>
                </Divider>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <Select
                    field="scrumMasterId"
                    useFormRef={useFormRef}
                    schema={schema}
                    options={listaScrumMasters}
                    getOptionLabel={(option) => option.nome}
                    disabled={disabledAlocacao()}
                  />
                </FormControl>
              </>
            )}

            <Divider>
              <h3>Alocação de especialista</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="auditorId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaAuditores}
                getOptionLabel={(option) => option.nome}
                disabled={disabledAlocacao()}
              />
            </FormControl>
            {(diagnostico?.id || diagnosticoId) && exibirAuditor && auditorId && (
              <React.Fragment>
                <DadosDiagnostico
                  dados={diagnostico}
                  open={openDadosDiagnostico}
                  onSave={onSaveDadosDiagnostico}
                  onCancel={onCancelDadosDiagnostico}
                  disabled={disabled()}
                />
                <DadosBenchmarking
                  processo={currProcesso}
                  final={false}
                  open={openDadosBenchmarking}
                  onClose={onCloseDadosBenchmarking}
                  disabled={disabled()}
                />
                <Divider>
                  <h3>Documentos</h3>
                </Divider>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="pendenciasId"
                        disabled={disabled()}
                        fileData={diagnostico?.pendencias}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <Checkbox
                        field="pendenciasSanadas"
                        disabled={disabled()}
                        schema={schema}
                        useFormRef={useFormRef}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="relatorioId"
                        disabled={disabled()}
                        fileData={diagnostico?.relatorio}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <Checkbox
                        field="aprovadoCliente"
                        disabled={disabled()}
                        schema={schema}
                        useFormRef={useFormRef}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FileListField
                  field="outrosDocs"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={diagnostico?.outrosDocs}
                />

                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    field="comentariosEsp"
                    multiline
                    rows={4}
                    useFormRef={useFormRef}
                    schema={schema}
                    disabled={disabled()}
                  />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    field="comentariosRev"
                    multiline
                    rows={4}
                    useFormRef={useFormRef}
                    schema={schema}
                    disabled={disabledValidador() && disabledGci()}
                  />
                </FormControl>

                <Grid container spacing={2} marginTop={"4px"}>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <FileField
                      field="termoApresDiagId"
                      disabled={disabled()}
                      fileData={diagnostico?.termoApresDiag}
                      useFormRef={useFormRef}
                      schema={schema}
                    />
                  </FormControl>
                </Grid>

                {(diagnostico?.termoApresDiag || termoApresDiagId) && (
                  <React.Fragment>
                    <Divider>
                      <h3>Modalidade da Implementação do Projeto</h3>
                    </Divider>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"center"}
                      margin={"12px 0 12px 0"}
                    >
                      <FormControl sx={{ my: 2 }} fullWidth>
                        <Select
                          field="modalidadeFinanciamento"
                          useFormRef={useFormRef}
                          schema={schema}
                          options={listaModalidadesFinanciamentos}
                          getOptionLabel={(option) => option.nome}
                          disabled={disabledAlocacao()}
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                )}
                {modalidadeFinanciamento === "F" && (
                  <React.Fragment>
                    <Divider>
                      <h3>Alocação de insituição financeiras</h3>
                    </Divider>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <Select
                        field="instFinanceiraId"
                        useFormRef={useFormRef}
                        schema={schema}
                        options={listaInstFinanceiras}
                        getOptionLabel={(option) => option.nome}
                      // disabled={disabledAlocacao()}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
                {modalidadeFinanciamento === "N" && (
                  <React.Fragment>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <Select
                        field="motivoEncerramento"
                        useFormRef={useFormRef}
                        schema={schema}
                        options={listaMotivosEncerramento}
                        getOptionLabel={(option) => option.label}
                        fullWidth
                        disabled={disabledAlocacao()}
                      />
                    </FormControl>
                    {motivoWatch === 7 && (
                      <FormControl sx={{ my: 2 }} fullWidth>
                        <TextField
                          autoFocus
                          margin="dense"
                          fullWidth
                          field="observacoesEncerramento"
                          multiline
                          rows={4}
                          useFormRef={useFormRef}
                          schema={schema}
                          disabled={disabledAlocacao()}
                        />
                      </FormControl>
                    )}
                    {showObsErrorMsg && (
                      <div style={{ color: "red" }}>
                        *Necessário especificar o motivo nas observações
                      </div>
                    )}
                  </React.Fragment>
                )}
                {!["N", "R", "B"].includes(modalidadeFinanciamento) && (
                  <React.Fragment>
                    <Divider>
                      <h3>Aprovações</h3>
                    </Divider>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                          <Checkbox
                            field="aprovadoValidador"
                            disabled={disabledValidador()}
                            schema={schema}
                            useFormRef={useFormRef}
                          />
                        </FormControl>
                      </Grid>
                      {modalidadeFinanciamento &&
                        !["N", "R", "B"].includes(modalidadeFinanciamento) && (
                          <Grid item xs={6}>
                            <FormControl sx={{ my: 2 }} fullWidth>
                              <Checkbox
                                field="aprovadoGci"
                                disabled={disabledGci()}
                                schema={schema}
                                useFormRef={useFormRef}
                              />
                            </FormControl>
                          </Grid>
                        )}
                    </Grid>
                  </React.Fragment>
                )}

                {currProcesso && currProcesso.estagio != "diagIniciado" && (
                  <React.Fragment>
                    <Divider>
                      <h3>
                        Observações de{" "}
                        {currProcesso.estagio == "diagReprovado"
                          ? "reprovação"
                          : "aprovação"}
                      </h3>
                    </Divider>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <MUITextField
                        value={diagnostico?.observacoes}
                        multiline
                        rows={4}
                        disabled={true}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
                {currProcesso && currProcesso.estagio === "encSemImplementacao" && (
                  <React.Fragment>
                    <Divider>
                      <h3>Observações de Encerramento</h3>
                    </Divider>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <MUITextField
                        value={diagnostico?.observacoesEncerramento}
                        multiline
                        rows={4}
                        disabled={true}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <Timestamps dados={diagnostico} />

            {(!disabled() ||
              (disabled() && currProcesso.estagio === "encSemImplementacao") ||
              !disabledValidador() ||
              !disabledGci()) && (
                <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons()}</Box>
              )}
          </form>
        </>
      )}
    </Paper>
  );
};

export default Diagnostico;
